import React from "react";
import classNames from "classnames";

import exampleCard from "../../assets/example-card.jpg";
import "./style.scss";

type ExampleCardProps = {
  classname?: string;
};

const ExampleCard = ({ classname }: ExampleCardProps) => {
  return (
    <div className={classNames(["example-card-wrapper", classname])}>
      <img src={exampleCard} alt="Example Card" />
    </div>
  );
};

export default ExampleCard;
