import React from "react";

export const InformationBox = () => {
  return (
    <div className="registration-information-box">
      <span>
        The information you provide below will be used to facilitate your demo
        experience.We may also use your information to contact you in accordance
        with our{" "}
        <a
          href="https://corp.trialcard.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </span>
    </div>
  );
};
