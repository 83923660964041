import React from "react";
import { Button, ModalProps } from "antd";
import FauxModal from "./modal";
import Spacer from "../Spacer";

const ConfirmationModal = (props: ModalProps) => {
  return (
    <FauxModal {...props} className="confirmation-modal">
      <div className="modal-content">
        <Spacer height={28} type="flex" />
        <h3>Thanks for enrolling in the CIZPLAM Hub Program</h3>
        <Spacer height={10} type="flex" />
        <p>What's next?</p>
        <ul>
          <li>
            You’ll receive an email shortly with access to your MyCIZPLAM demo
            account.
          </li>
          <li>
            Complete your account registration to activate your PatientLink
            demonstration experience.
          </li>
        </ul>
        <Spacer height={30} type="flex" />

        <Button className="confirmation-done-button" onClick={props.onCancel}>
          Done
        </Button>
        <Spacer height={50} type="flex" />
      </div>
    </FauxModal>
  );
};

export default ConfirmationModal;
