import React from "react";
import { Row, Col } from "antd";

import ExampleCard from "../components/ExampleCard";
import TermsAndConditions from "../components/TermsConditions";
import Layout, { LayoutContentContainer } from "../layout";
import HeaderBodySeparator from "../layout/header/body-seperator";
import MobileBanner from "../components/MobileAppBanner";
import Spacer from "../components/Spacer";
import EnrollmentCard from "../components/EnrollmentCard";
import { ReactComponent as ClipBoardIcon } from "../assets/clipboard-icon.svg";
import { ReactComponent as ActivateIcon } from "../assets/activate-icon.svg";
import { ReactComponent as ReplaceIcon } from "../assets/replace-icon.svg";
import WelcomeContainer, {
  WelcomeDescription,
  WelcomeHeading,
  WelcomeHighlightText,
} from "../components/Introduction";
import RegistrationForm from "../components/RegistrationForm";
import { useRegistrationForm } from "../components/RegistrationForm/hook";

const CopayPage = () => {
  const { openRegistrationModal, registrationFormVisible } =
    useRegistrationForm();

  return (
    <Layout>
      <HeaderBodySeparator />
      <LayoutContentContainer>
        <div className="welcome-container-wrapper">
          <div className="description">
            <WelcomeContainer>
              <WelcomeHeading>
                Welcome to the MyCIZPLAM® Co-pay Program
              </WelcomeHeading>
              <WelcomeDescription>
                MyCIZPLAM is about providing you personalized support throughout
                your patient care journey. Get one-on-one assistance finding
                ways to save on the cost of your prescription and staying on
                track on CIZPLAM.
              </WelcomeDescription>
              <WelcomeHighlightText className="desktop-view hub">
                Get savings on your out-of-pocket medication costs for CIZPLAM®.
              </WelcomeHighlightText>
            </WelcomeContainer>
          </div>
          <div className="card-section">
            <div className="home-example-card-container">
              <div className="card">
                <ExampleCard classname="" />
              </div>
              <div className="example-card-desc copay-fix">
                <h4>Pay as little as $5 per dose*</h4>
                <p>
                  *$20,000 maximum program benefit per calendar year. Terms
                  expire at the end of each calendar year and may change. See
                  program requirements below.
                </p>
              </div>
            </div>
            <WelcomeHighlightText className="mobile-view">
              Get savings on your out-of-pocket medication costs for CIZPLAM®.
            </WelcomeHighlightText>
          </div>
        </div>
        <Spacer type="flex" height={30} />
        <Row gutter={12}>
          <RegistrationForm
            visible={registrationFormVisible}
            programType="CP"
          />
          <Col sm={12}>
            <EnrollmentCard
              action={openRegistrationModal}
              actionText="Enroll Now"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              title="Enroll in Program"
              icon={<ClipBoardIcon />}
            />
          </Col>
          <Col sm={12}>
            <EnrollmentCard
              action={openRegistrationModal}
              actionText="Activate Your Card"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              title="Activate a card"
              icon={<ActivateIcon />}
            />
          </Col>
          <Col sm={12}>
            <EnrollmentCard
              action={openRegistrationModal}
              actionText="Replace a Card"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              title="Order a new card"
              icon={<ReplaceIcon />}
            />
          </Col>
        </Row>
        <Spacer type="flex" height={43} />
        <MobileBanner />
        <Spacer type="flex" height={46} />
        <TermsAndConditions />
        <Spacer type="flex" height={20} />
      </LayoutContentContainer>
    </Layout>
  );
};

export default CopayPage;
