import { createStore, combineReducers } from "redux";
import { appReducer } from "./reducers";

const store = createStore(
  combineReducers({
    appStore: appReducer,
  })
);

export default store;
