import { useDispatch, useSelector } from "react-redux";
import { UPDATE_REGISTRATION_FORM_STATE } from "../../store/actions";

export const useRegistrationForm = () => {
  const dispatch = useDispatch();
  const registrationFormVisible = useSelector(
    (state: any) => state.appStore.registrationFormVisible
  );

  const closeRegistrationModal = () => {
    dispatch({
      type: UPDATE_REGISTRATION_FORM_STATE,
      payload: false,
    });
  };

  const openRegistrationModal = () => {
    dispatch({
      type: UPDATE_REGISTRATION_FORM_STATE,
      payload: true,
    });
  };

  return {
    openRegistrationModal,
    closeRegistrationModal,
    registrationFormVisible,
  };
};
