import React from "react";
import { Modal, ModalProps } from "antd";
import classNames from "classnames";

import { ReactComponent as ModalCloseIcon } from "../../assets/modal-close-button.svg";

const FauxModal: React.FC<ModalProps> = ({ children, ...modalProps }) => {
  return (
    <Modal
      {...modalProps}
      footer={null}
      title={null}
      closeIcon={<ModalCloseIcon />}
      className={classNames("registration-form", modalProps.className)}
      wrapClassName="registration-form-wrapper"
      width={696}
    >
      {children}
    </Modal>
  );
};

export default FauxModal;
