import React from "react";

export const ErrorBox: React.FC = ({ children }) => {
  if (!children) return null

  return (
    <div className="registration-error-box">
      <span>
        {children}
      </span>
    </div>
  );
};
