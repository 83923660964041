import React from "react";
import FauxDrawer from "./drawer";
import Footer from "./Footer";
import Header from "./Header";

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <FauxDrawer />
      <div className="layout-wrapper">
        <Header />
        <div className="layout-wrapper--content">{children}</div>
        <Footer />
      </div>
    </>
  );
};

export const LayoutContentContainer: React.FC = ({ children }) => {
  return <div className="layout-content-container">{children}</div>;
};

export default Layout;
