import React from "react";
import { ReactComponent as MangoLogo } from "../assets/mango-logo.svg";

const Footer = () => {
  return (
    <footer>
      <div className="mango-logo">
        <MangoLogo />
      </div>
      <Copyright />
    </footer>
  );
};

const Copyright = () => {
  return (
    <p className="copyright-text">
      © 2022 PatientLink, a Trialcard Company. All Rights Reserved.
    </p>
  );
};

export default Footer;
