import classNames from "classnames";
import React from "react";

import "./style.scss";

export const WelcomeHeading: React.FC = ({ children }) => {
  return <h2 className="welcome-container--heading">{children}</h2>;
};

export const WelcomeDescription: React.FC = ({ children }) => {
  return <p className="welcome-container--description">{children}</p>;
};

export const WelcomeHighlightText: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames(["welcome-container--highlight", className])}>
      {children}
    </div>
  );
};

const WelcomeContainer: React.FC = ({ children }) => {
  return <section className="welcome-container">{children}</section>;
};

export default WelcomeContainer;
