import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RegistrationForm from "../../components/RegistrationForm";
import { useRegistrationForm } from "../../components/RegistrationForm/hook";
import {
  TOGGLE_DRAWER
} from "../../store/actions";

const EnrollmenButton: React.FC<{ to: string }> = ({ children }) => {
  const dispatch = useDispatch();
  const { openRegistrationModal, registrationFormVisible } =
    useRegistrationForm();

  const { drawerVisible } = useSelector((store: any) => store.appStore);

  return (
    <>
      <RegistrationForm visible={registrationFormVisible} programType="HB" />
      <button
        className="enrollment-button"
        onClick={(e) => {
          if (drawerVisible) {
            dispatch({
              type: TOGGLE_DRAWER,
            });
          }
          openRegistrationModal();
        }}
      >
        {children}
      </button>
    </>
  );
};

export default EnrollmenButton;
