import React, { useState } from "react";
import { ModalProps, Row, Col, Form, Input, Button } from "antd";
import MaskedInput from "antd-mask-input";
import queryString from "query-string";

import Spacer from "../Spacer";
import { CoverageSelection, FormHorizontalSpace, FormLabel } from "./elements";
import FauxModal from "./modal";
import ConfirmationModal from "./confirmation_modal";
import apiRequest from "../../utils/network";
import {isValidEmail, isValidPhoneNumber} from "../../utils/validators";

import { InformationBox } from "./information_box";
import { ErrorBox } from "./error_box";
import "./style.scss";
import { useDispatch } from "react-redux";
import { UPDATE_REGISTRATION_FORM_STATE } from "../../store/actions";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";
import {useCurrentProgramId} from "../../programs/hooks";

const demoInitialValues = {
  coverage: "commercial",
  condition: "Advanced Heart Disease",
  birth_date: "September 9, 1976",
  address_line_1: "2250 Perimeter Park Drive",
  city: "Morrisville",
  zip_code: "94107",
  state: "NC",
};

const RegistrationForm = ({
  programType,
  ...modalProps
}: ModalProps & { programType: "HB" | "CP" }) => {
  const [form] = Form.useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState("");
  const { search } = useLocation();

  const programId = useCurrentProgramId();

  const { isDemo = true, program_id=programId } = queryString.parse(search);

  const dispatch = useDispatch();

  const closeRegistrationModal = () => {
    form.resetFields();
    setFormSubmitted(false);
    dispatch({
      type: UPDATE_REGISTRATION_FORM_STATE,
      payload: false,
    });
  };

  return (
    <>
      <ConfirmationModal
        destroyOnClose
        visible={showConfirmation}
        onCancel={() => {
          setShowConfirmation(false);
        }}
      />
      <FauxModal {...modalProps} onCancel={closeRegistrationModal}>
        <div className="registration-form--content">
          <Spacer height={24} type="flex" />
          <h3>Get Enrolled</h3>
          <Spacer height={4} type="flex" />
          <InformationBox />
          {!!errors && <ErrorBox>{errors}</ErrorBox>}
          <p>
            Fill in the fields below to enroll in the CIZPLAM Support Program.
          </p>
          <Spacer height={12} type="flex" />
          <Form
            form={form}
            layout={"vertical"}
            initialValues={demoInitialValues}
            requiredMark={false}
            onFinish={async (values) => {
              if (!isValidEmail(values.email)) {
                form.setFields([
                  {
                    name: "email",
                    errors: ["Email address is invalid"],
                  },
                ]);
                return;
              }

              setFormSubmitted(true);
              setErrors("");
              const formValues = {
                ...values,
                birth_date: new Date(values.birth_date).getTime(),
                phoneNumber: `+1-${values.phoneNumber.replaceAll("-", "")}`,
                is_demo: Boolean(isDemo),
              };

              if (!!program_id) {
                formValues['program_id'] = program_id;
              }
              
              try {
                await apiRequest({
                  method: "POST",
                  endpoint: "/api/v1/partner-registration/",
                  data: formValues,
                });

                closeRegistrationModal();
                setShowConfirmation(true);
              } catch (e) {
                const { response } = e as AxiosError;
                console.log(response?.data);
                if (response?.data) {
                  setErrors(response.data.message);
                }
                setFormSubmitted(false);
              }
            }}
          >
            <Spacer height={12} type="flex" />
            <Row gutter={24}>
              <Col md={12} xs={24}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "First name is required",
                    },
                  ]}
                  label={<FormLabel>First Name</FormLabel>}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Last name is required",
                    },
                  ]}
                  label={<FormLabel>Last Name</FormLabel>}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
              <FormHorizontalSpace />
              <Col md={12} xs={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required",
                    },
                  ]}
                  label={<FormLabel>Email Address</FormLabel>}
                >
                  <Input placeholder="name@domain.com" />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required",
                    },
                    () => ({
                      validator(_, value) {
                        if (isValidPhoneNumber(value.replaceAll("-", ""))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Phone number is not valid")
                        );
                      },
                    }),
                  ]}
                  name="phoneNumber"
                  label={<FormLabel>Phone Number</FormLabel>}
                >
                  <MaskedInput mask="111-111-1111" placeholder="000-000-0000" />
                </Form.Item>
              </Col>
              <FormHorizontalSpace />
              <Col md={12} xs={24}>
                <Form.Item
                  name="partner"
                  label={<FormLabel>Company</FormLabel>}
                  rules={[
                    {
                      required: true,
                      message: "Company name is required",
                    },
                  ]}
                >
                  <Input placeholder="Company" />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="address_line_1"
                  label={<FormLabel>Address Line 1</FormLabel>}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <FormHorizontalSpace />
              <Col md={12} xs={24}>
                <Form.Item
                  name="address_line_2"
                  label={<FormLabel>Address Line 2</FormLabel>}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item name="city" label={<FormLabel>City</FormLabel>}>
                  <Input disabled />
                </Form.Item>
              </Col>

              <FormHorizontalSpace />
              <Col md={12} xs={24}>
                <Form.Item name="state" label={<FormLabel>State</FormLabel>}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="zip_code"
                  label={<FormLabel>Zip Code</FormLabel>}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="birth_date"
                  label={<FormLabel>Date of Birth</FormLabel>}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={24}>
                <h4>Insurance and Health Information</h4>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="condition"
                  label={<FormLabel>Your Condition</FormLabel>}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label={<FormLabel>Insurance Coverage</FormLabel>}
                  name="coverage"
                >
                  <CoverageSelection
                    items={[
                      {
                        id: "commercial",
                        title: "Commercial Healthcare Plan",
                        description:
                          "That I pay for myself or get through work",
                      },
                      {
                        id: "medicare",
                        title: "Medicare, Medicade, VA",
                        description: "Or goverment funded insurance plan",
                      },
                      {
                        id: "none",
                        title: "I don’t have insurance",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <FormHorizontalSpace />

              <Col md={24}>
                <Form.Item>
                  <Button
                    loading={formSubmitted}
                    className="registration-submit-button"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </FauxModal>
    </>
  );
};

export default RegistrationForm;
