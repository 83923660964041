import React from "react";

import { ReactComponent as Logo } from "../assets/logo.svg";
import TopInformationBar from "./information_bar";

import "./style.scss";
import { DrawerToggleButton } from "./header/drawer-toggle-button";
import HeaderMenu from "./header/header-menu";

const Header = () => {
  return (
    <header>
      <TopInformationBar />
      <div className="header-content">
        <div className="logo-wrapper">
          <Logo />
        </div>
        <HeaderMenu />
        <div className="drawer-toggle">
          <span>
            <DrawerToggleButton />
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
