import React from "react";

const InformationItem: React.FC = ({ children }) => {
  return <div className="information-bar--item">{children}</div>;
};

const TopInformationBar = () => {
  return (
    <div className="information-bar">
      <InformationItem>Important Saftety Information</InformationItem>
      <InformationItem>Prescribing Information</InformationItem>
    </div>
  );
};

export default TopInformationBar;
