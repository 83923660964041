import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as DrawerIcon } from "../../assets/drawer-icon.svg";
import { ReactComponent as SmallDrawerIcon } from "../../assets/drawer-menu-small-icon.svg";
import { TOGGLE_DRAWER } from "../../store/actions";

export const DrawerToggleButton = () => {
  const dispatch = useDispatch();

  const toggleDrawerState = () => {
    dispatch({
      type: TOGGLE_DRAWER,
    });
  };

  return (
    <Button type="link" className="drawer-icon-wrapper" onClick={toggleDrawerState}>
      <span className="big-drawer-icon">
        <DrawerIcon />
      </span>
      <span className="small-drawer-icon">
        <SmallDrawerIcon />
      </span>
    </Button>
  );
};
