import React from "react";

import "./style.scss";

type EnrollmentCardProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  actionText: string;
  action: () => void;
};

const EnrollmentCard = (props: EnrollmentCardProps) => {
  const { action, actionText, description, icon, title } = props;
  return (
    <a
      href="!#"
      role="button"
      className="enrollment-card"
      onClick={(e) => {
        e.preventDefault();
        action();
      }}
    >
      <div className="enrollment-card--icon">{icon}</div>
      <h3 className="enrollment-card--title">{title}</h3>
      <p className="enrollment-card--description">{description}</p>
      <span className="enrollment-card--action">
        {actionText}
      </span>
    </a>
  );
};

export default EnrollmentCard;
