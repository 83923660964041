import React from "react";

import Spacer from "../../components/Spacer";

import { HeaderMenuItem } from "./menu-item";
import { ReactComponent as CardIcon } from "../../assets/card.svg";
import { ReactComponent as CardManagementIcon } from "../../assets/card-management.svg";
import EnrollmenButton from "./enrollment-button";

const HeaderMenu = () => {
  
  return (
    <div className="header-menu">
      <HeaderMenuItem icon={<CardIcon />} path="/">
        Affording Your Medication
      </HeaderMenuItem>
      <Spacer className="header-menu-item-spacer" width={24} />
      <HeaderMenuItem icon={<CardManagementIcon />} path="/">
        Understanding Your Coverage
      </HeaderMenuItem>
      <Spacer className="enrollment-button-separator" width={39} />
      <div className="drawer-divider" style={{ display: "none" }} />
      <EnrollmenButton to="/">Enroll in MyCIZPLAM Program</EnrollmenButton>
    </div>
  );
};

export default HeaderMenu;
