import React from "react";
import { Row, Col } from "antd";

import ExampleCard from "../components/ExampleCard";
import TermsAndConditions from "../components/TermsConditions";
import Layout, { LayoutContentContainer } from "../layout";
import MobileBanner from "../components/MobileAppBanner";
import Spacer from "../components/Spacer";
import EnrollmentCard from "../components/EnrollmentCard";
import { ReactComponent as ClipBoardIcon } from "../assets/hub-clipboard.svg";
import { ReactComponent as Savings } from "../assets/hub-savings.svg";
import { ReactComponent as Tracking } from "../assets/hub-tracking.svg";
import { ReactComponent as CreditCard } from "../assets/hub-credit-card.svg";
import WelcomeContainer, {
  WelcomeDescription,
  WelcomeHeading,
  WelcomeHighlightText,
} from "../components/Introduction";
import HubBanner from "../components/HubBanner";
import RegistrationForm from "../components/RegistrationForm";
import { useRegistrationForm } from "../components/RegistrationForm/hook";

const HubPage = () => {
  const {
    openRegistrationModal,
    registrationFormVisible,
  } = useRegistrationForm();

  return (
    <Layout>
      <HubBanner />
      <LayoutContentContainer>
        <div className="welcome-container-wrapper">
          <div className="description">
            <WelcomeContainer>
              <WelcomeHeading>
                Welcome to the MyCIZPLAM® Support Program
              </WelcomeHeading>
              <WelcomeDescription>
                MyCIZPLAM is about providing you personalized support throughout
                your patient care journey. Get one-on-one assistance finding
                ways to save on the cost of your prescription and staying on
                track on CIZPLAM.
              </WelcomeDescription>
              <WelcomeHighlightText className="desktop-view hub">
                Get savings on your out-of-pocket medication costs for CIZPLAM®.
              </WelcomeHighlightText>
            </WelcomeContainer>
          </div>
          <div className="card-section">
            <div className="home-example-card-container">
              <div className="card">
                <ExampleCard classname="remove-top hub-fix" />
              </div>
              <div className="example-card-desc">
                <h4>Pay as little as $5 per dose*</h4>
                <p>
                  *$20,000 maximum program benefit per calendar year. Terms
                  expire at the end of each calendar year and may change. See
                  program requirements below.
                </p>
              </div>
            </div>
            <WelcomeHighlightText className="mobile-view">
              Get savings on your out-of-pocket medication costs for CIZPLAM®.
            </WelcomeHighlightText>
          </div>
        </div>

        <Spacer type="flex" height={30} />
        <Row gutter={12}>
          <RegistrationForm
            visible={registrationFormVisible}
            programType="HB"
          />
          <Col sm={12}>
            <EnrollmentCard
              action={openRegistrationModal}
              actionText="Enroll Now"
              description="We’re here to help you find ways to save on the cost of your prescription. "
              title="Financial Assistance"
              icon={<CreditCard />}
            />
          </Col>
          <Col sm={12}>
            <EnrollmentCard
              action={openRegistrationModal}
              actionText="Enroll Now"
              description="Walk through your benefits with to a MyCIZPLAM Benefits Experts"
              title="Understand your Benefits"
              icon={<ClipBoardIcon />}
            />
          </Col>
          <Col sm={12}>
            <EnrollmentCard
              action={openRegistrationModal}
              actionText="Enroll Now"
              description="Submit rebates online through your MyCIZPLAM account."
              title="Get Rebates on Medication"
              icon={<Savings />}
            />
          </Col>
          <Col sm={12}>
            <EnrollmentCard
              action={openRegistrationModal}
              actionText="Enroll Now"
              description="Take advantage of the services available to you."
              title="Access Additional Services"
              icon={<Tracking />}
            />
          </Col>
        </Row>
        <Spacer type="flex" height={43} />
        <MobileBanner />
        <Spacer type="flex" height={46} />
        <TermsAndConditions />
        <Spacer type="flex" height={20} />
      </LayoutContentContainer>
    </Layout>
  );
};

export default HubPage;
