import { TOGGLE_DRAWER, UPDATE_REGISTRATION_FORM_STATE } from "./actions";

const initialState = {
  drawerVisible: false,
  registrationFormVisible: false,
};

export const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        drawerVisible: !state.drawerVisible,
      };
    case UPDATE_REGISTRATION_FORM_STATE:
      return {
        ...state,
        registrationFormVisible: action.payload,
      };
    default:
      return state;
  }
};
