import React from "react";

import appStorePromoImg from "../../assets/download-on-the-app-store.jpg";
import googlePlayPromoImg from "../../assets/get-it-in-google-play.png";
import Spacer from "../Spacer";
import "./style.scss";

const MobileBanner = () => {
  return (
    <section className="mobile-banner">
      <div className="mobile-banner--bg-image" />
      <div className="mobile-banner--promotion-text">
        <h3>Go Mobile and download the PatientLink App</h3>
        <p>Always stay connected for a personalized experience.</p>
      </div>
      <div className="mobile-banner--download-links">
        <AppStoreDownloadLink />
        <Spacer height={10} />
        <GooglePlayDownloadLink />
      </div>
    </section>
  );
};

const AppStoreDownloadLink = () => {
  return (
    <div className="app-store-img">
        <a href="https://apps.apple.com/us/app/patientlink-by-trialcard/id1635680252" target="_blank">
            <img src={appStorePromoImg} alt="Download from App Store" />
        </a>
    </div>
  );
};

const GooglePlayDownloadLink = () => {
  return (
    <div className="play-store-img">
      <a href="https://play.google.com/store/apps/details?id=com.tc.patientlink" target="_blank">
        <img src={googlePlayPromoImg} alt="Download from Play Store" />
      </a>
    </div>
  );
};

export default MobileBanner;
