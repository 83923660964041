import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_DRAWER } from "../../store/actions";
import { ReactComponent as DrawerCloseIcon } from "../../assets/drawer-close-button.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";

import "./style.scss";
import HeaderMenu from "../header/header-menu";

const FauxDrawer = () => {
  const { drawerVisible } = useSelector((store: any) => store.appStore);
  const dispatch = useDispatch();

  const closeDrawer = () => {
    dispatch({
      type: TOGGLE_DRAWER,
    });
  };

  return (
    <Drawer
      width={273}
      closeIcon={<DrawerCloseIcon />}
      visible={drawerVisible}
      onClose={closeDrawer}
      className="faux-drawer-wrapper"
    >
      <div className="logo-wrapper">
        <Logo />
      </div>
      <div className="drawer-divider" />
      <h5>Menu</h5>
      <HeaderMenu />
    </Drawer>
  );
};

export default FauxDrawer;
