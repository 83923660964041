import React from "react";

type HeaderLinkProps = {
  path: string;
  icon: React.ReactNode;
};

export const HeaderMenuItem: React.FC<HeaderLinkProps> = ({
  path,
  children,
  icon,
}) => {
  return (
    <a href={path} className="header-menu--item">
      <span className="icon">{icon}</span>
      <span className="label">{children}</span>
    </a>
  );
};
