import React from "react";
import { Col, Row, Tooltip } from "antd";
import classNames from "classnames";

import Spacer from "../Spacer";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";

type CoverageSelectionItemProps = {
  selected?: boolean;
  title: string;
  description?: string;
  className?: string;
  onSelect?: () => void;
  id: string;
};

type CoverageSelectionProps = {
  onChange?: (val: string) => void;
  value?: string;
  disabled?: boolean;
  items?: CoverageSelectionItemProps[];
};

const CoverageSelectionCard = ({
  onSelect,
  className,
  selected = false,
  title,
  description,
}: CoverageSelectionItemProps) => {
  return (
    <a
      href="!#"
      role="button"
      onClick={(e) => {
        e.preventDefault();
        onSelect!();
      }}
      className={classNames([
        "coverage-selection-card",
        className,
        { selected },
      ])}
    >
      <h4>{title}</h4>
      {description && <p>{description}</p>}
    </a>
  );
};

export const CoverageSelection = ({
  onChange,
  value,
  disabled = false,
  items = [],
}: CoverageSelectionProps) => {
  return (
    <Row
      gutter={16}
      className={classNames(["coverage-selection", { disabled }])}
    >
      {items.map((props, index) => {
        return (
          <Col md={8} sm={24} xs={24} key={index}>
            <CoverageSelectionCard
              {...props}
              selected={props.id === value}
              onSelect={() => {
                // onChange!(props.id);
              }}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export const FormHorizontalSpace = () => {
  return (
    <Col md={24}>
      <Spacer height={6} type="flex" />
    </Col>
  );
};

export const FormLabel: React.FC = ({ children }) => {
  return (
    <span className="form-label-with-info">
      {children}
      <span className="icon">
        <Tooltip title="tooltip content">
          <span>
            <InfoIcon />
          </span>
        </Tooltip>
      </span>
    </span>
  );
};
