import { useLocation } from 'react-router-dom';

const HUB_PATH_NAME = '/hub';
const isHostCizplam = process?.env?.REACT_APP_HOST === 'cizplam';

const HUB_PROGRAM_ID = isHostCizplam
  ? '159348c4-23ed-4cdd-b057-1958b138779c' // default program_id for cizplam production env
  : '47375249-7770-47fd-a63f-20b4da3913f7'; // default program_id for demo production env
const COPAY_PROGRAM_ID = isHostCizplam
  ? 'e559f516-c802-4dbe-8a92-b46fad5bd1da'
  : 'd954fb7f-6aa0-4e02-8dfd-bca3835f60bc';

export const useCurrentProgramId = () =>
  useLocation().pathname === HUB_PATH_NAME ? HUB_PROGRAM_ID : COPAY_PROGRAM_ID;
