import "antd/dist/antd.less";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./styles/main.scss";

import CopayPage from "./programs/copay";
import HubPage from "./programs/hub";
import reportWebVitals from "./reportWebVitals";
import FauxDrawer from "./layout/drawer";

import reduxStore from "./store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <Router>
        <Switch>
          <Route path="/copay" component={CopayPage} />
          <Route path="/hub" component={HubPage} />
          <Redirect path="*" to="/hub" />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
