import React, { useEffect, useRef, useState } from "react";
import Popover from "@reactour/popover";
import { useRect } from "@reactour/utils";
import { Mask } from "@reactour/mask";

import RegistrationForm from "../RegistrationForm";
import Spacer from "../Spacer";
// import { ReactComponent as PopoverArrowIcon } from "../../assets/popup-arrow-icon.svg";

import "./style.scss";
import store from "../../store";
import { useRegistrationForm } from "../RegistrationForm/hook";

const HubBanner = () => {
  return (
    <div className="hub-banner-wrapper">
      <h1>Welcome to the MyCIZPLAM® Support Program</h1>
      <Spacer height={20} />
      <HubCTAButton />
    </div>
  );
};

type HubPopoverProps = {
  onClose: () => any;
  showForm: (e: any) => any;
};

const HubPopover: React.FC<HubPopoverProps> = ({ onClose, showForm }) => {
  return (
    <div className="hub-popover-wrapper" onClick={onClose}>
      <p className="strong-text">
        Get started with your demonstration of TrialCard’s patient experience.
      </p>
      <p>
        Enroll in our example support program to start your journey as a new
        patient.
      </p>
      <p>
        Receive your MyCIZPLAM account and explore the PatientLink demonstration
        experience.
      </p>
      <div className="popover-footer">
        <a
          href="!#"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Got it
        </a>
      </div>
    </div>
  );
};

const HubCTAButton = () => {
  const buttonRef = useRef(null);
  const [updater] = useState([]);
  const sizes = useRect(buttonRef, updater);
  const [isOpen, setIsOpen] = useState(false);
  const { openRegistrationModal, registrationFormVisible } =
    useRegistrationForm();

  const closePopover = () => {
    document.body.classList.remove("block-scroll");
    setIsOpen(false);
  };

  useEffect(() => {
    if (registrationFormVisible && isOpen) {
      closePopover();
    }
  }, [registrationFormVisible, isOpen]);

  const openPopover = () => {
    const {
      appStore: { registrationFormVisible },
    } = store.getState();
    if (!registrationFormVisible) {
      window.scroll(0, 0);
      document.body.classList.add("block-scroll");
      setTimeout(() => {
        setIsOpen(true);
      }, 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      openPopover();
    }, 4500);
  }, []);

  return (
    <>
      <RegistrationForm visible={registrationFormVisible} programType="HB" />
      <button
        ref={buttonRef}
        className="hub-banner-cta-button"
        onClick={openRegistrationModal}
      >
        Get started with the MyCIZPLAM Support Program
      </button>
      {isOpen && (
        <>
          <Mask
            sizes={sizes}
            styles={{ maskWrapper: (base) => ({ ...base, zIndex: 99999 }) }}
            onClick={closePopover}
          />
          <Popover sizes={sizes} className="demo-popover">
            <HubPopover
              onClose={closePopover}
              showForm={openRegistrationModal}
            />
          </Popover>
        </>
      )}
    </>
  );
};

export default HubBanner;
